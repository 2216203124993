import {useState} from 'react';
import PropTypes from 'prop-types';
import {styled} from '@mui/material/styles';
import {Footer} from './footer';
import {MainNavbar} from './main-navbar';
import {MainSidebar} from './main-sidebar';

const MainLayoutRoot = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingTop: 64
}));

export const MainLayout = ({children}) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    return (
        <MainLayoutRoot>
            <MainNavbar onOpenSidebar={() => setIsSidebarOpen(true)}/>
            <MainSidebar
                onClose={() => setIsSidebarOpen(false)}
                open={isSidebarOpen}
            />
            {children}
            <Footer/>
        </MainLayoutRoot>
    );
};

MainLayout.propTypes = {
    children: PropTypes.node
};
