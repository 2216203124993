import {useEffect, useState} from 'react';
import {useRouter} from 'next/router';
import PropTypes from 'prop-types';
import {useAuth} from '../../hooks/use-auth';

export const AuthGuard = (props) => {
    const {children} = props;
    const auth = useAuth();
    const router = useRouter();
    const [checked, setChecked] = useState(false);

    useEffect(() => {
            if (!router.isReady) {
                return;
            }

            if (!auth.isAuthenticated) {
                const returnUrl = (
                    router?.asPath === undefined ||
                    router.asPath === null ||
                    router.asPath === "/"
                )? "/dashboard" : router.asPath
                router.push({
                    pathname: '/authentication/login',
                    query: {returnUrl: returnUrl}
                }).catch(console.error);
            } else {
                setChecked(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [router.isReady, auth.isAuthenticated]);

    if (!checked) {
        return null;
    }

    // If got here, it means that the redirect did not occur, and that tells us that the user is
    // authenticated / authorized.

    return <>{children}</>;
};

AuthGuard.propTypes = {
    children: PropTypes.node,
    defaultRedirect: PropTypes.string
};
